import React, { useEffect } from "react";
import {
  Heading,
  Box,
  Page,
  Button,
  Layout,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);

  const instance = new URLSearchParams(window.location.search).get("instance");

  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [secretKey, setSecretKey] = React.useState("");
  const [publicKey, setPublicKey] = React.useState("");
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/autopilot/_functions", // Replace with your API base URL
      applicationID: "6c1e1fa2-d332-4c92-9b45-0933f85b8d53", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getSettings();
  }, []);

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.wixsite.com/autopilot/_functions`;

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (secretKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            secretKey,
            publicKey,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setSecretKey("");
        setPublicKey("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>Autopilot</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? (
                <Button
                  skin="premium"
                  as="a"
                  href={basePlatform.getUpgradeUrl()}
                  target={"_blank"}
                  prefixIcon={<Icons.PremiumFilled />}
                >
                  {isUpgraded ? "Manage Plan" : "Accept Online Payments"}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Layout></Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
